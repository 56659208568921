//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import chineseLanguageLoader from "chinese-language-loader/lib/main.js";

export default {
  props: ["product"],
  data() {
    return {
      locale: window.localStorage.getItem("locale") || this.$i18n.locale,
    };
  },
  created() {
    const uniacid = window.localStorage.getItem("uniacid");

    if (this.locale === "zh-CN") {
      this.product.goods_name = chineseLanguageLoader(this.product.goods_name, {
        language: "zh-CN",
      });
    } else if (this.locale === "EN") {
      switch (parseInt(uniacid)) {
        case 17:
          this.updateProductNameForUnacid17();
          break;
        case 20:
          this.updateProductNameForUnacid20();
          break;
        case 24:
          this.updateProductNameForUnacid24();
          break;
        case 29:
          this.updateProductNameForUnacid29();
          break;
      }
    }
  },
  methods: {
    detail(id) {
      this.$router.push({ path: "/product", query: { id } });
    },
    updateProductNameForUnacid17() {
      const names = {
        NS_01: "Slim Head Toothbrush",
        NS_02: "Double Layer Bristle Toothbrush",
        NS_03: "Deep Cleansing Toothbrush (Super Soft Bristles)",
        NS_04: "Deep Cleansing Toothbrush (Regular Bristles)",
        NS_05: "Toothbrush (Soft Bristles)",
        NS_06: "Long Handle Interdental Brush - 0.6MM (pack of 6)",
        NS_07: "Long Handle Interdental Brush - 0.7MM (pack of 6)",
        NS_08: "Long Handle Interdental Brush - 0.5MM (pack of 6)",
      };

      this.product.goods_name = names[this.product.goods_id] || this.product.goods_name;
    },
    updateProductNameForUnacid20() {
      const names = {
        UF_001: "Sabre Course 8 Sessions",
        UF_002: "Foil Beginner Class</br> (Primary School)",
        UF_003: "Children's Fencing Course (Ages 3-6) 8 Sessions",
        UF_004: "Adult Fencing Training </br>Class",
      };

      this.product.goods_name = names[this.product.goods_id] || this.product.goods_name;
    },
    updateProductNameForUnacid24() {
      const names = {
        SK_001: "Mini storage for 12 months, size 4x3, 12 square feet",
        SK_002: "Mini storage for 12 months, size 5x3, 15 square feet",
        SK_003: "Mini storage for 12 months, size 4x4, 16 square feet",
        SK_004: "Mini storage for 12 months, size 4x5, 20 square feet",
        SK_005: "Mini storage for 12 months, size 5x6, 30 square feet",
        SK_007: "Smart storage for 12 months, size 4x4, 16 square feet",
      };

      this.product.goods_name = names[this.product.goods_id] || this.product.goods_name;
    },
    updateProductNameForUnacid29() {
      const names = {
        London_01: "London City Island",
        London_02: "Zone 1, London (ZONE 1)",
        London_03: "UPTON GARDENS, London (ZONE 3)",
        London_04: "LONG & WATERSON, London (ZONE 1)",
        London_05: "LON 091",
        London_06: "POSTMARK, London (ZONE 1)",
        London_07: "ZONE 2, London (ZONE 2)",
        London_08: "London (Zone 3)",
        London_09: "CALICO WHARF, London (ZONE 2)",
        London_10: "THE AURIA, London (ZONE 2)",
      };

      this.product.goods_name = names[this.product.goods_id] || this.product.goods_name;
    },
  },
};
