//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Search from '@/components/Search'
import Product from '@/components/Product'
export default {
    name: 'Home',
    components: {Search, Product},
	data() {
		return {
			moduleList: [],
			newslist: [],
			leimugoodslist: [],
			banner: [],
			menu: {
				active: '',
				list: []
			},
			leimulist:[],
			size: 10,
			page: 1,
			pageCount: 0,
			goodsList: [],
		}
	},
	created() {	
		this.getarticlelist()
		this.getHomeLayout()
	 
	
	},
	methods: {
		detail(id) {
		    this.$router.push({ path:'/detail', query:{id}})
		},
		//获取新闻列表
		async getarticlelist(){
			let res = await this.$http.get('/app.layout/articlelist',{})
				console.log('alist',res);
			if(res){
			
				this.newslist = res
				}
			}
		,
		// 获取首頁模块
		async getHomeLayout() {
			let res = await this.$http.get('/app.layout', {
				url: '/pages/tabbar/index/index'
			})
			if (res.code !== 0) return
			this.moduleList = res.data.list
			this.banner = res.data.list.reduce((arr,item)=>{
				item.type == 'carousel' && (arr = item.data)
				return arr
			},[])
		},

		//获取分类
		async getleimu(){
			let res = await this.$http.get('/app.layout/mulu',{})
			if(res){
				this.menu.list = res.itemtitle.map((item,index)=>{
					let obj = {label: item, id: res.itemcode[index]}
					return  obj
				})
			}
		},
		async getleimugoods(){
			let res = await this.$http.get('/app.layout/getmulugoods',{})
			if(res){
				console.log('gsl',res);
				   this.leimugoodslist = res 
			}
		},
		
		async getleimu1(){
				console.log('mulu1');
				let res = await this.$http.get('/app.layout/mulu1', {
					 	 
				})
					  if(res){
						  console.log(res);
						  this.leimulist = res 
						 
						  console.log('ok');
					  }
				
		},
		async getleimu2(){
				if(uni.getStorageSync('category_one1')){
					this.category_one=uni.getStorageSync('category_one1')
					uni.setStorageSync("category_one1", '')
					console.log('one',this.category_one);
				}
				let res = await uni.$http.get('/app.layout/mulu2', {
					 father_code:this.category_one	 
				})
					  if(res.data){
						  this.leimutitle = res.data
					      console.log(this.leimutitle);
						  console.log('ok:leimu2');
					  }
				
		},

		//点击分类
		async onClickItem(e,f) {
			this.menu.active = e
			this.goodsList=[]
			this.page=0
			//this.getGoodsList()
		},

		//获取产品列表
		async getGoodsListT(one,two) {
			this.menu.active = two
			if (this.page > 1 && this.page - 1 >= this.pageCount) return
			this.isLoading = true
			const res = await this.$http.get('/goods', {
				page: 0,
				size: this.size,
				category_one: one,
				category_two: two,
			})
			console.log('resgoods',res);
			this.isLoading = false
			if (res.code !== 0) return
			let data = res.data
		    this.leimugoodslist[one]['goods']=res.data.list
		
		 
		},
		//获取产品列表
		async getGoodsList(category_two) {
			if (this.page > 1 && this.page - 1 >= this.pageCount) return
			this.isLoading = true
			const res = await this.$http.get('/goods', {
				page: this.page++,
				size: this.size,
				category_one: this.menu.active,
				category_two: category_two,
			})
			console.log('resgoods',res);
			this.isLoading = false
			if (res.code !== 0) return
			let data = res.data
			this.pageCount = Math.ceil(data.total / this.size)
			if (this.page <= 2) this.goodsList = []
			this.goodsList = this.goodsList.concat(data.list)
		},
	}
}
