//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import chineseLanguageLoader from "chinese-language-loader/lib/main.js";
import Search from '@/components/Search';
import Product2 from '@/components/Product2';
// import productDetails from './productDetails.json';
import productDetails from '@/assets/productDetails.json';
export default {
    name: 'Product',
    components: { Search, Product2 },
    data() {
        return {
            uniacid: parseInt(window.localStorage.getItem('uniacid')),
            webset: [],
            leimugoodslist: [],
            productInfo: null,
            goodsId: '',
            detail: '',
            goods: '',
            number: 1,
            color: '',
            size: '',
            location: '',
            goods_sku_key: '',
            locale: window.localStorage.getItem('locale') || this.$i18n.locale,
            currency: window.localStorage.getItem('currency'),
            productDetails: productDetails
        };
    },
    created() {
        this.goodsId = this.$route.query.id;
        const ss = window.localStorage.getItem('webinfo');
        const ssarray = JSON.parse(ss);

        this.webset = ssarray;
        this.uniacid = ssarray['ucid'];

        this.getGoodsDetail();
        this.getGoodsListT("Luggage", "");
    },
    computed: {
        productNameAndDetails() {
            if (this.productInfo && this.productInfo.basic && this.productInfo.basic.field) {
                const uniacid = this.uniacid;
                const goodsId = this.productInfo.basic.field.goods_id;

                if (this.productDetails[uniacid] && this.productDetails[uniacid][goodsId]) {
                    return this.productDetails[uniacid][goodsId];
                }
            }
            return null;  // Return null if no matching product details found
        }
    },
    methods: {
        // 下单
        orderConfirm() {
            this.onConfirmClick('buy');
        },

        async onConfirmClick(code) {
            const res = await this.$http.post('/cart/add', {
                size: this.$refs.sizeselect.value,
                user_id: window.localStorage.getItem('user_id'),
                goods_id: this.goodsId,
                goods_sku_key: this.productInfo.sku.list[0].goods_sku_key,
                goods_number: this.number,
                color: this.$refs.colorselect.value,
                location: this.$refs.locationselect.value,
                promotion_code: this.productInfo.basic.field.promotion_code,
                is_checked: 1,
                is_direct: code === 'buy' ? 1 : 0
            });

            if (res.code !== 0) {
                return this.$message.error(res.message);
            } else {
                if (code === 'buy') {
                    this.$router.push({ name: 'orderConfirm', params: { cart_id: res.data.cart_id } });
                }
            }
        },

        // 修改数量
        changeNumber(type) {
            if (type === 'add') {
                this.number += 1;
            } else {
                this.number > 1 && (this.number -= 1);
            }
        },

        // 获取商品信息
        async getGoodsDetail() {
            if (!this.goodsId) return;
            const res = await this.$http.get('/goods/detail', {
                goods_id: this.goodsId
            });

            const data = res.data;
            data.basic.field.detail_mobile = JSON.parse(data.basic.field.detail_mobile);
            this.detail = data.basic.field.detail;
            this.goods = data.basic.field;
            this.productInfo = res.data;

            const uniacid = this.uniacid;

            if (this.locale === 'zh-CN') {
                const goods_name = chineseLanguageLoader(this.productInfo.basic.field.goods_name, { language: 'zh-CN' });
                this.productInfo.basic.field.goods_name = goods_name;

                const detail = chineseLanguageLoader(this.productInfo.basic.field.detail, { language: 'zh-CN' });
                this.productInfo.basic.field.detail = detail;
            } else if (this.locale === 'EN') {
                const productDetails = this.productNameAndDetails;
                if (productDetails) {  // Ensure productDetails are defined
                    this.productInfo.basic.field.goods_name = productDetails.name;
                    this.productInfo.basic.field.detail = productDetails.detail;
                }
            }
        },

        // 添加到购物车
        async addCart(code) {
    let selectedsize = '';

    if (this.uniacid === 20 && this.goodsId === 'S11') {
        selectedsize = this.$refs.sizeselect.value.toString();
    }

    const res = await this.$http.post('/cart/add', {
        user_id: window.localStorage.getItem('user_id'),
        goods_id: this.goodsId,
        goods_sku_key: this.productInfo.sku.list[0].goods_sku_key,
        goods_number: this.number,
        size: selectedsize,
        color: this.$refs.colorselect.value.toString(),
        promotion_code: this.productInfo.promotion_code,
        is_checked: 1,
        is_direct: code === 'buy' ? 1 : 0
    });

    if (res.code !== 0) {
        if (res.data.code === 401) {
            window.location.href = '/#/login';
            return false;
        }
        let errorMessage = res.message;

        // Debugging: Log the message and locale
        console.log("Locale:", this.locale);  // Check if the locale is 'EN'
        console.log("Original Message:", errorMessage);  // Log the original message

        // Check if locale is EN and message needs translation
        if (this.locale.toUpperCase() === 'EN' && errorMessage.includes('請先登錄')) {
            errorMessage = 'Please login first';
        }

        // Display the (translated or original) error message
        return this.$message.error(errorMessage);

    } else {
        let successMessage = '添加成功';

        // Translate success message to English if locale is EN
        if (this.locale.toUpperCase() === 'EN') {
            successMessage = 'Added successfully';
        }

        // Display the translated or original success message
        this.$message({ message: successMessage, type: 'success' });
    }
},


        async getGoodsListT(one, two) {
            switch (this.uniacid) {
                case 4: one = "A11"; break;
                case 5: one = "none"; break;
                case 6: one = "Curtain rod"; break;
                case 7: one = "AB"; break;
                case 8: one = "warehouse"; break;
                case 9: one = "jiankang"; break;
                case 10: one = "YTOFFICE"; break;
                case 11: one = "無綫連接儀錶 ( 外置藍牙模組 )"; break;
                case 12: one = "15g Tube"; break;
                case 14: one = "Rake"; break;
                case 15: one = "消毒儀器"; break;
                case 16: one = "新產品"; break;
                case 17: one = "專業牙刷系列"; break;
                case 18: one = "Toy Car"; break;
                case 19: one = "Discount item"; break;
                case 20: one = "課程"; break;
                case 21: one = "Insurance Products"; break;
                case 22: one = "Custom-made"; break;
                case 23: one = "tea"; break;
                case 24: one = "Storage"; break;
                case 25: one = "小電器"; break;
                case 26: one = "Grain Moisture Meter"; break;
                case 27: one = "Insurance Services"; break;
                case 28: one = "Financial Advisory Services"; break;
                case 29: one = "London"; break;
                case 30: one = "Earphones"; break;
                case 31: one = "原創麵譜"; break;
                default: one = "none"; break;
            }
            const res = await this.$http.get('/goods', {
                page: 0,
                size: 5,
                category_one: one,
                category_two: two,
            });
            console.log(res);

            this.isLoading = false;
            if (res.code !== 0) return;
            this.leimugoodslist = res.data.list;

            console.log("leimugoodslist: " + this.uniacid);
            console.log(this.leimugoodslist);
        },
    }
};
